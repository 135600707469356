<template>
  <v-list-item
    class="pa-0 description-content"
    v-bind="$attrs"
    v-on="$listeners"
    v-if="Object.keys(description).length"
    three-line
  >
    <v-list-item-content class="py-0 py-md-6">
      <v-list-item-title
        class="ma-0 pb-10 pb-sm-18 pb-md-4 descriptionTitle--text"
        :class="isTablet ? 'font-normal' : 'font-title-mobile'"
      >{{ description.title }}</v-list-item-title>
      <v-list-item-subtitle
        v-for="(item, index) in description.text"
        :key="index"
        class="ma-0 font-normal descriptionText--text d-none d-md-block"
      >
        {{ item }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  name: 'TextDescription',
  inheritAttrs: false,
  props: {
    description: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isTablet() {
      return this.$vuetify.breakpoint.width > 1024
    }
  },
}
</script>
