<template>
  <div class="switcher-container" :class="bgColor">
    <input
        v-bind="$attrs"
        type="checkbox"
        :checked="value"
        :id="_uid"
        @input="$emit('input', $event.target.checked)"
        class="checkbox"
    >
    <label
      :for="_uid"
      class="switch"
      :class="{
        'on': value,
        'off': !value
      }"
    ></label>
  </div>
</template>

<script>
  export default {
    name: 'SwitchLg',
    inheritAttrs: false,
    props: {
      value: {
        type: Boolean,
        default: false
      },
      bgColor: {
        type: String,
        default: 'switcherYellow'
      }
    }
  };
</script>

<style lang="scss">
@import '../../sass/var';
.switcher-container {
  height: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background: var(--v-switchBg-base);
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
  
  &:after{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--v-switchBg-base);
    border: 1px solid var(--v-switchBorder-base);
    border-radius: 2px;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
}

.switcherYellow {
  .on {
    &.switch {
      background: $switcherBgYellow;
    
      &::after {
        left: 20px;
        background: $switcherBgYellow;
        border-color: $switcherYellow;
      }
    }
  }
}

.switcherViolet {
  .on {
    &.switch {
      background: var(--v-switchActiveBg-base);
    
      &::after {
        left: 20px;
        background: transparent;
        border-color: var(--v-switchActiveBorder-base);
      }
    }
  }
}

.off {
  &.switch {
    &::after {
      left: 0;
      background: transparent;
    }
  }
}

.checkbox {
  display: none;
}
</style>
